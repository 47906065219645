import { Box, Flex, Text, Image, Spacer } from "@chakra-ui/react"
import viablLogo from "../../assets/viablLogo.png"

export const PageContainer = (props: {children: React.ReactNode}) => {
    return (
        <Flex direction='column' h='full' w='full'>
            <Box bgGradient='linear-gradient(90deg, rgba(6,87,156,1) 49%, rgba(245,250,254,1) 86%, rgba(255,255,255,1) 95%);' borderBottomColor={"var(--Able-yellow)"} borderBottomWidth={"3px"} shadow={"base"}>
                <Flex direction='row' justifyContent='flex-between' w='full' h={{base: 75, md: 90, lg: 100, xl: 110}}>
                    <Flex direction='column' justify='center' ml={{base: 2, md: 5, lg: 10}}>
                        <Text color={"white"} fontSize={{base:"xl", md:"3xl", lg: "4xl", xl: "5xl"}} textAlign={"left"}>Viabl Password Reset</Text>
                    </Flex>
                    <Spacer/>
                    <Image src={viablLogo} align='flex-end'></Image>
                </Flex>
            </Box>
            {props.children}
        </Flex>
    )
}