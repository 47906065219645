import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import { Routing } from './routes/Routing';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <BrowserRouter>
          <Routing/>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
