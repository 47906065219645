import * as React from 'react';
import { Routes, Route, Navigate, useParams, useSearchParams } from 'react-router-dom';
import { ResetPage } from '../pages/ResetPage';

export function Routing(){
    const [searchParams, setSearchParams] = useSearchParams();

    function ReDirect() {
        const { requestId } = useParams();
        let route: string = "/" + requestId;

        let returnUrl = searchParams.get("redirectUrl");
        if (returnUrl !== null) {
            route = route + "?redirectUrl=" + returnUrl;
        }
    
        return <Navigate replace to={route}/>
    }

    return (
        <Routes>
            <Route path="/" element={
                <ResetPage/>
            } />

            <Route path="/:requestId" element={
                <ResetPage/>
            }/>

            <Route path="/password/reset/:requestId" element={
                <ReDirect/>
            } />
        </Routes>
    )
}
