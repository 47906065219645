import { useState, useEffect } from 'react';
import { Card, CardBody, HStack, Checkbox, Button, Input, Flex, Link, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Box, FormControl, FormLabel, VStack, UnorderedList, ListItem, FormErrorMessage, Spinner } from '@chakra-ui/react';
import viablLogo from "../assets/viablLogo.png"
import { useWebHelper } from '../../hooks/UseWebHelper';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PageContainer } from '../PageContainer';

export const ResetPage = () => {
    const { requestId } = useParams();
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [isDifferent, setIsDifferent] = useState<boolean>(false);
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

    const [password, setPassword] = useState<string>("");
    const [repeatPassword, setRepeatPassword] = useState<string>("");

    useEffect(() => {
        GetRequest();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            let returnUrl = searchParams.get("redirectUrl");

            if (returnUrl !== null) {
                setIsRedirecting(true);
                console.log(returnUrl);
                if (returnUrl.startsWith("www")){
                    returnUrl = "https://" + returnUrl;
                }

                window.location.href = returnUrl;
            }
        }
    }, [isSuccess])

    const ValidateAndSubmit = async () => {
        setIsEmpty(false);
        setIsDifferent(false);

        if (password === "") {
            setIsEmpty(true);
            return;
        }

        if (password !== repeatPassword) {
            setIsDifferent(true);
            return;
        }

        SubmitRequest();
    }

    const SubmitRequest = async () => {
        setIsError(false);
        setIsWorking(true);
        setIsSuccess(false);

        var requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'password': password })
        }

        try
        {
            let response: Response = await (fetch("/api/reset/send/" + requestId, requestOptions))

            console.log("Status code" + response.status);

            if (response.ok) {
                setIsSuccess(true);
            } else if (response.status == 401) {
                setIsValid(false);
            } else {
                setIsError(true);
            }
        }
        catch (err)
        {
            console.error(err);
            setIsError(true);
        }
        setIsWorking(false);
    };

    const GetRequest = async () => {
        var requestOptions = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        }

        try 
        {
            let response: Response = await (fetch("/api/reset/attempt/" + requestId, requestOptions));

            if (response.ok) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
        catch (err)
        {
            console.error(err);
            setIsValid(false);
        }

        setIsLoading(false);
    }

    return (
        <PageContainer>
            <Flex direction='column' align='center' h='full'>
                {!isLoading &&
                    <Flex direction='column' align='center' justify='center' h='full' m={1} maxW='xl'>
                        {isSuccess ? 
                        <>
                            <Alert status='success' justifyContent='center' alignItems='center' flexDirection='column' minW={{xl: "xl"}}>
                                <AlertIcon boxSize='80px'/>
                                <AlertTitle fontSize='xl' mt={5}>Success</AlertTitle>
                                <AlertDescription mt={2}>Your password has been successfully reset.</AlertDescription>
                            </Alert>

                            {isRedirecting &&
                                <Flex mt={4}>
                                    <Spinner emptyColor='gray.200' mr={5}/>
                                    <Text>You are being redirected. Please wait...</Text>
                                </Flex>  
                            }
                        </>
                        :
                        isValid ? 
                            <Card p={4} minW={{xl: "xl"}}>
                                <Flex direction='column'>
                                    <Text>You have requested a password reset for your Viabl account</Text>
                                    <FormControl isRequired mt={4} isInvalid={isEmpty}>
                                        <FormLabel>New Password</FormLabel>
                                        <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                                        <FormErrorMessage>Password is required</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isRequired mt={2} isInvalid={isDifferent}>
                                        <FormLabel>Confirm Password</FormLabel>
                                        <Input type='password' value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}/>
                                        <FormErrorMessage>Passwords should match</FormErrorMessage>
                                    </FormControl>
                                    
                                    <Text mt={4} align='start' fontSize='sm'><b>Please ensure your password:</b></Text>
                                    <UnorderedList textAlign='start' fontSize='sm'>
                                        <ListItem >Has at least one uppercase and lowercase letter</ListItem>
                                        <ListItem>Has at least one number 0-9</ListItem>
                                        <ListItem>Has at least one special character ! @ # $ & *</ListItem>
                                        <ListItem>Is at least 8 characters long</ListItem>
                                    </UnorderedList>

                                    <Button mt={4} justifySelf='center' bg={"var(--Able-blue)"} color={'white'} spinnerPlacement='end' isLoading={isWorking} loadingText='Submitting' onClick={ValidateAndSubmit}>Reset Password</Button>
                                    {isError &&
                                        <Alert status='error' mt={2}>
                                            <AlertIcon/>
                                            <Box textAlign='start'>
                                                <AlertTitle>Something went wrong</AlertTitle>
                                                <AlertDescription>Please ensure your password matches the criteria and is different from your previous password.</AlertDescription>
                                            </Box>
                                        </Alert>
                                    }
                                </Flex>
                            </Card> 
                        :
                            <Alert status='warning' justifyContent='center' alignItems='center' flexDirection='column' minW={{xl: "xl"}}>
                                <AlertIcon boxSize='80px'/>
                                <AlertTitle fontSize='xl' mt={5}>Invalid Reset Request</AlertTitle>
                                <AlertDescription fontSize='lg' mt={5}>Your password reset request is either invalid or has expired.</AlertDescription>
                                <AlertDescription fontSize='lg' mt={2}>Please request a new password reset.</AlertDescription>
                            </Alert>
                        }
                    </Flex>
                }
            </Flex>
        </PageContainer>
    )
}
